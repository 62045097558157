import React from 'react';
import Card from './Card.js';
class FetchAmiibos extends React.Component {

    state = {
        isFetching: true,
        amiibos: null,
        searchType: null
    }

    // Adapated from: https://blog.logrocket.com/patterns-for-data-fetching-in-react-981ced7e5c56/#howtofetchdatainreact
    async componentDidMount() {
        const url = "https://amiiboapi.com/api/amiibo/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({amiibos: data.amiibo, isFetching: false})
    }

    render() {

        // While fetching
        if(this.state.isFetching)
        {
            return(
                <div>Fetching...</div>
            );
        }

        // Check if the amiibos array is null
        if(!this.state.amiibos)
        {
            return(
                <div>Did not return any Amiibos...</div>
            );
        }

        // Return the Amiibo info as Cards, filtered to only show Figures released in North America
        return(
            <div className="cards">
                {this.state.amiibos.filter((amiibo) => {

                    // check state

                    return(amiibo.type === 'Figure' && amiibo.release.na != null);
                }).map((amiibo) => (
                    <Card amiibo={amiibo} key={amiibo.image}/>
                ))}
            </div>
        );
    }
}

export default FetchAmiibos;