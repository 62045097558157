import "./styles.css";
import FetchAmiibos from "./FetchAmiibos.js";

function App() {
  return (
    <div className="App">
      <div className="centered">
        <div className="header">
          <h1>Nintendo Amiibos</h1>
          <p>By: Adam Hemeon - W0434257</p>
          <p>Technologies: React, SCSS, Gulp</p>
          <p>JSON data retrieved from: <a href="https://amiiboapi.com/">https://amiiboapi.com/</a></p>
          <p>This page displays all Nintendo Amiibos of type 'Figure' released in North America.</p>
        </div>
        <FetchAmiibos />
      </div>
    </div>
  );
}

export default App;
