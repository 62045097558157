import React from "react";

function Card(props) {
    
    return(
        <div className="card">
            <div className="image">
                <img src={`${props.amiibo.image}`} alt={`${props.amiibo.image}`} />
            </div>
            <div className="container">
                <h3 className="name"><strong>{`${props.amiibo.name}`}</strong></h3>
                <ul className="details">
                    <li><b>Character: </b>{`${props.amiibo.character}`}</li>
                    <li><b>Series: </b>{`${props.amiibo.amiiboSeries}`}</li>
                    <li><b>Game: </b>{`${props.amiibo.gameSeries}`}</li>
                    <li><b>Type: </b>{`${props.amiibo.type}`}</li>
                    <li><b>Released in NA: </b>{`${props.amiibo.release.na}`}</li>
                </ul>
            </div>
        </div>
    );
}

export default Card;
